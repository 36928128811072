/*============================================================================*\
  Product miniature
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@if $add-class {
  .product-preview {
    overflow: visible !important;

    .product-thumbnail {
      overflow: visible !important;
    }
  }

  .product-miniature__dot-hover {
    opacity: 0;
    transform-origin: center center;
    transition:
      transform 0.2s ease-in-out,
      opacity 0.2s ease-in-out;
  }

  .product-miniature__dot-hover--1 {
    top: -13px;
    right: -13px;
    width: 56px;
    height: 56px;
    transform: translate(-50%, 50%) scale(0.5);
  }

  .product-miniature__dot-hover--2 {
    bottom: 37.14%;
    left: -10px;
    width: 30px;
    height: 30px;
    transform: translate(50%, -20%) scale(0.5);
  }

  .product-miniature__image-link {
    border-radius: 20px;
    transition: box-shadow 0.2s;

    img {
      border-radius: 20px;
      transform: none !important;
    }

    &:hover {
      box-shadow: 0px 10px 20px -5px rgba(11, 26, 55, 0.22);

      .product-miniature__dot-hover {
        opacity: 1;
      }

      .product-miniature__dot-hover--1 {
        transform: translate(0, 0) scale(1);
      }

      .product-miniature__dot-hover--2 {
        transform: translate(0, 0) scale(1);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:20";