/*==========================================================================*\
  Footer
\*==========================================================================*/

/* stylelint-disable */

#footer {
  @apply bg-white;
  margin-top: 0;
}

/* Reassurance
\*==========================================================================*/

.footer-reassurance {
  @apply border-b border-blue border-opacity-10;
  padding: 2rem 0;

  .block-reassurance-item {
    img {
      max-width: 86px;
      max-height: 86px;
    }
  }

  @screen s {
    padding: 5.5rem 0;
  }
}

/* Social
\*==========================================================================*/

#footer {
  .social-links {
    li {
      a {
        @apply text-blue;

        &:hover,
        &:focus {
          @apply text-white;
        }
      }
    }
  }
}

/* Copyright
\*==========================================================================*/

.footer-copy {
  white-space: nowrap;
  color: black !important;
}

.footer-bottom {
  display: flex;
  flex-direction: column;

  .jms-megamenu {
    .nav > li {
      padding: 0;
      margin-left: 10px;

      a {
        font-size: 14px;
        color: theme('colors.black') !important;
      }
    }
  }

  @screen s {
    flex-direction: row;
    align-items: center;
  }
}
;@import "sass-embedded-legacy-load-done:5";