.select-search__options {
  z-index: 1;
  position: relative;
}

.select-search__options {
  ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 250px;
    margin-top: theme('margin.2');
    padding: theme('padding[2.5]') theme('padding.4');
    overflow-y: auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: theme('boxShadow.card');
  }

  p {
    color: theme('colors.grey.dark');
  }

  button {
    width: 100%;
    padding: theme('padding.2') theme('padding.4');
    color: theme('colors.black');
    text-align: left;
    border-radius: 8px;
  }

  button:hover,
  button:focus {
    background: theme('backgroundColor.grey.lighter');
  }
}

;@import "sass-embedded-legacy-load-done:25";