/*============================================================================*\
  Breadcrumb
\*============================================================================*/

/* stylelint-disable */

$add-class: true !default;

/* Basic
\*==========================================================================*/

@if $add-class {
  .breadcrumb-wrapper {
    .breadcrumb {
      li {
        &:not(:last-child) {
          &:after {
            content: '/';
            display: inline-block;
            margin: 0 10px !important;
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:15";