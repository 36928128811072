/*============================================================================*\
  Wysiwyg
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin wysiwyg {
  @apply text-black;

  // Title
  h1 {
    @apply type-h1;
  }

  h2 {
    @apply type-h2;
    line-height: 42px;
  }

  h3 {
    @apply type-h3;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: 700;
  }

  // Inline tag
  strong,
  b {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  a:not(.btn) {
    text-decoration: underline;
    cursor: pointer;

    &:hover,
    &:focus {
      @apply text-yellow;
    }
  }

  // Paragraph
  p {
    line-height: 26px;
    background: inherit !important;
    @apply font-body #{!important};
  }

  // Image and figure
  img {
    max-width: 100%;
    height: auto;
  }

  figure {
    margin-bottom: 2em;
  }

  figcaption {
    @apply text-small;
  }

  // Blockquote
  blockquote {
    @apply type-h3 font-body font-regular border-l-2 border-grey-medium;
    padding: 0.125em 2em;
  }

  // List
  ul {
    @apply font-body;
    list-style-type: none;

    li {
      position: relative;
      padding-left: 18px;

      &:before {
        @apply bg-yellow;
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }

    li + li {
      margin-top: 6px;
    }
  }

  ol {
    @apply font-body #{!important};

    li {
      list-style-type: decimal;
    }
  }

  // Datalist
  dl {
    display: flex;
    flex-wrap: wrap;

    dt {
      font-weight: 500;
    }

    dd,
    dt {
      flex: 0 0 45%;
      margin: 0.125rem;
      padding: 0.625rem;
      @apply bg-grey-medium;

      &:nth-of-type(even) {
        @apply bg-grey;
      }
    }
  }

  // All tag vertical marge
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:first-child) {
      margin-top: 1.5em;
      margin-bottom: 0.85rem;
    }

    span {
      font: inherit !important;
      background: inherit !important;
    }
  }

  /* stylelint-disable no-descending-specificity */
  p,
  img,
  figure,
  blockquote,
  ul,
  ol,
  dl,
  pre {
    span {
      font: inherit !important;
      background: inherit !important;
    }

    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }
  /* stylelint-enable */

  td {
    padding: 1rem;
  }

  table,
  th,
  td {
    border: 1px solid black;

    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  // Product miniature
  .product-miniature {
    .product-thumbnail {
      img {
        margin-bottom: 0;
      }
    }

    .product-link {
      text-decoration: none;

      &:hover {
        @apply text-blue;
        text-decoration: underline;
      }
    }
  }

  @screen s {
    p {
      line-height: 30px;
      background: inherit !important;
      @apply font-body #{!important};
    }
  }
}

@if $add-class {
  .wysiwyg {
    @include wysiwyg;
  }
}

.wysiwyg--large {
  p {
    font-size: theme('fontSize.body');
    line-height: 30px;
    color: black;
  }

  @screen m {
    p {
      font-size: theme('fontSize.medium');
    }
  }
}

.wysiwyg--small {
  p {
    @apply text-small leading-6;
  }
}

.wysiwyg--cms {
  text-align: left;

  img {
    width: 100%;
    height: auto;
    overflow: hidden;
    @apply rounded-xl mb-10;
  }

  ul,
  li {
    list-style-type: none;
  }

  p:not(:has(img)),
  table,
  h1:not(:has(img)),
  h2:not(:has(img)),
  h3:not(:has(img)),
  h4:not(:has(img)),
  ul:not(:has(img)),
  ol:not(:has(img)) {
    float: none !important;
    max-width: 800px;
    margin: 0 auto;
    text-align: left !important;
  }
}

;@import "sass-embedded-legacy-load-done:23";