/*============================================================================*\
Register Form
\*============================================================================*/

.register-form {
  .col-md-3 {
    @screen m {
      flex: 0 0 15%;
      max-width: 15%;
    }
  }
}

;@import "sass-embedded-legacy-load-done:21";