/*============================================================================*\
  Field text
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin field-text {
  @apply text-black #{!important};
  display: block !important;
  width: 100% !important;
  height: auto !important;
  padding: 16px 10px;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  background-color: white !important;
  border: 1px solid theme('colors.grey.light') !important;
  border-radius: 10px;
  outline: none !important;
  transition: border-color 0.1s !important;

  &::placeholder {
    color: theme('colors.grey.dark') !important;
  }

  // Form group status
  .field-group.has-error & {
    @apply text-notice-red #{!important};
    border-bottom-color: #{theme('colors.notice-red')} !important;
    outline: none !important;
  }

  &.is-filled + label,
  &:not(.is-filled):focus + label,
  &:not(.is-filled):autofill + label,
  &:not(.is-filled):required:autofill + label,
  &:not(.is-filled):required:valid + label {
    right: 10px;
    left: auto;
    font-size: 14px;
    pointer-events: none;
    transition: 0.3s all ease-out;
  }

  &:focus {
    border-color: #{theme('colors.blue.DEFAULT')} !important;
  }

  &:disabled {
    background-color: theme('backgroundColor.grey.lighter') !important;
  }

  label {
    transition: 0.3s all ease-out;
  }
}

@if $add-class {
  .field-text {
    @include field-text;
  }

  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='email'],
  input[type='url'],
  input[type='search'],
  input[type='tel'] {
    @include field-text;
  }
}

;@import "sass-embedded-legacy-load-done:11";