/*============================================================================*\
  Price style
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin price {
  @apply type-body font-regular text-blue;
}

@if $add-class {
  .price {
    @include price;
  }
}

/* Crossed
\*==========================================================================*/

@mixin price-old {
  text-decoration: line-through;
  opacity: 0.2;
}

@if $add-class {
  .price.old {
    @include price-old;
  }
}

/* Promo
\*==========================================================================*/

@mixin price-promo {
  @apply text-yellow;
}

@if $add-class {
  .price--promo {
    @include price-promo;
  }
}

;@import "sass-embedded-legacy-load-done:8";