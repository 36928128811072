/*============================================================================*\
  Categories
\*============================================================================*/

/* stylelint-disable */

$add-class: true !default;

/* Basic
\*==========================================================================*/

@if $add-class {
  .section-categorylist {
    .pb-banner {
      > a {
        &:before {
          content: '';
          z-index: 2;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: black;
          opacity: 0.6;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:17";