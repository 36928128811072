/**
* title-dots-decoration
**/
.title-dots-decoration {
  .dot {
    @apply border-2 border-yellow;
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 999px;

    &:first-child {
      @apply bg-yellow;
      margin-right: 3px;
    }
  }
}

.title-dots-decoration--taupe {
  // stylelint-disable-next-line no-descending-specificity
  .dot {
    @apply bg-brown-dark border-2 border-brown-dark;

    &:first-child {
      @apply bg-yellow border-2 border-yellow;
      margin-right: 3px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:14";