/*============================================================================*\
  Field textarea
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin field-textarea {
  @apply text-black #{!important};
  display: block !important;
  width: 100% !important;
  height: auto !important;
  padding: 16px 10px;
  font-size: 16px !important;
  font-weight: 400 !important;
  background-color: white !important;
  border: 1px solid theme('colors.grey.light') !important;
  border-radius: 10px;
  outline: none !important;
  transition: border-color 0.1s !important;

  &:focus,
  &:autofill {
    border: 0;
    border-bottom: 1px solid #{theme('colors.blue.light')} !important;
  }

  &::placeholder {
    color: currentColor !important;
    opacity: 0.6 !important;
  }

  // Form group status
  .field-group.has-error & {
    @apply text-notice-red #{!important};
    border-color: #{theme('colors.notice-red')} !important;
    outline: none !important;
  }
}

@if $add-class {
  .field-textarea {
    @include field-textarea;
  }

  textarea {
    @include field-textarea;
  }
}

;@import "sass-embedded-legacy-load-done:13";