/*============================================================================*\
  Field number
\*============================================================================*/

/* stylelint-disable */

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin field-number {
  @apply bg-white;
  padding-right: 42px !important;
  padding-left: 42px !important;
  border-radius: 48px !important;

  input#quantity_wanted {
    border: 0 !important;
  }

  .input-group-btn-vertical {
    .bootstrap-touchspin-up,
    .bootstrap-touchspin-down {
      display: block;
      width: 42px !important;
      color: transparent;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: 36px;
      border: 0 !important;

      &:hover,
      &:focus {
        color: transparent;
      }
    }

    .bootstrap-touchspin-up {
      background-image: url('/themes/bpi/static/svg/field-number-up.svg');
      background-position: center right;
    }

    .bootstrap-touchspin-down {
      background-image: url('/themes/bpi/static/svg/field-number-down.svg');
      background-position: left center;
    }
  }
}

@if $add-class {
  .bootstrap-touchspin {
    @include field-number;
  }
}

;@import "sass-embedded-legacy-load-done:9";