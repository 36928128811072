/*============================================================================*\
  App styles
\*============================================================================*/

/* Config
\*==========================================================================*/

@import './config';

/* Prestashop theme
\*==========================================================================*/

// @import './theme/theme';

/* Utilities
\*==========================================================================*/

@import './utilities/mixins';

/* Base
\*==========================================================================*/

@import 'tailwindcss/base';
@import './base/global';
@import './base/override';
@import './base/header';
@import './base/footer';

/* Vendors
\*==========================================================================*/

// @import './vendors/fontawesome';

/* Elements
\*==========================================================================*/

@import './elements/btn';
@import './elements/link';
@import './elements/price';
@import './elements/field-number';
@import './elements/field-select';
@import './elements/field-text';
@import './elements/field-radio';
@import './elements/field-textarea';
@import './elements/title-dots-decoration';

/* Components
\*==========================================================================*/

@import 'tailwindcss/components';
@import './components/breadcrumb';
@import './components/breakpoints';
@import './components/categories';
@import './components/hero';
@import './components/pagination';
@import './components/product-miniature';
@import './components/register-form';
@import './components/slider';
@import './components/wysiwyg';
@import './components/colissimo-widget';
@import './components/select-search';

/* Utilities
\*==========================================================================*/

@import 'tailwindcss/utilities';

/* Modules
\*==========================================================================*/

@import './modules/all';

::selection {
  color: theme('colors.white');
  background-color: theme('colors.yellow.DEFAULT');
}

* {
  outline-color: theme('colors.yellow.DEFAULT');
}

html,
body {
  scroll-behavior: smooth;
}

.corner-r-l {
  border-radius: 16px 16px 0px 0;
  @screen m {
    &::after,
    &::before {
      content: '';
      position: absolute;
      width: 2em;
      height: 2em;
      background-color: transparent;
    }

    &::after {
      bottom: 0em;
      left: -2em;
      border-radius: 0px 0px 15px 0;
      box-shadow: 16px 0 0 0 white;
    }

    &::before {
      bottom: 0;
      left: 100%;
      border-radius: 0 0 0 15px;
      box-shadow: -16px 0 0 0 white;
    }
  }
}

.shoppingcart-box {
  pointer-events: none;
  opacity: 0;
  transition: 0.2s opacity ease-out;

  &.show {
    pointer-events: auto;
    opacity: 1;

    .shoppingcart-box__panel {
      transform: translateX(0);
    }
  }
}

.shoppingcart-box__panel {
  transform: translateX(100%);
  transition: 0.3s transform cubic-bezier(0.4, 0, 0.2, 1);
}

.collapse {
  display: none;
}

.collapse.show {
  display: block;
  visibility: visible;
}

.sm-table {
  font-size: 14px;
  color: black;

  th,
  tr,
  td {
    border: 1px solid theme('colors.grey.lighter');
  }

  td,
  th {
    padding: 20px 10px;
  }
}

.box {
  @apply shadow-card rounded-lg bg-white p-8;
  border: none !important;
}

.group-span-filestyle {
  label {
    @apply mt-2;
  }
}

.category-label__flag {
  @apply w-8 h-8;

  @screen s {
    @apply w-12 h-12;
  }
}
