/*==========================================================================*\
  Global
\*==========================================================================*/

/* Base
\*==========================================================================*/

// *,
// *:before,
// *:after {
//   position: relative;
// }

html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

body {
  @apply type-body font-regular text-blue-light bg-white;
}

/* Container
\*==========================================================================*/

// Page container
.page-center {
  width: 100%;
  width: calc(100% - #{theme('spacing.mobile-container-margin')});
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;

  > .grid {
    padding-right: 0;
    padding-left: 0;
  }
}

// Main container
.main-center {
  width: 100%;
  width: calc(100% - #{theme('spacing.mobile-container-margin')});
  max-width: 1216px;
  margin-right: auto;
  margin-left: auto;

  > .grid {
    padding-right: 0;
    padding-left: 0;
  }
}

// Section no space
.section--no-space {
  .layout-column {
    padding: 0;
  }
}

.form-group {
  &.has-error {
    .help-block {
      color: #e74c3c;
    }
  }
}

#checkout .form-group--radio-buttons {
  label {
    margin-bottom: 5px;
  }

  @screen s {
    &.input-is_business {
      margin-bottom: 10px;
    }
  }

  .form-control-label {
    display: block;
  }

  @screen s {
    .form-control-label {
      display: inline-block;
    }

    label {
      margin-bottom: 0px;
    }
  }
}

// CMS page
.page-content.page-cms {
  table {
    table-layout: fixed;
  }
}

;@import "sass-embedded-legacy-load-done:2";