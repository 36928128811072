/*============================================================================*\
  Hero
\*============================================================================*/

/* stylelint-disable */

$add-class: true !default;

/* Basic
\*==========================================================================*/

@if $add-class {
  .hero {
    min-height: 80vh;

    @screen xxl {
      height: 80vh;
      min-height: 0;
      max-height: 672px;
    }
  }

  .hero__media {
    &.m\:block {
      display: block !important;
      visibility: visible !important;
    }

    &.xl\:block {
      display: block !important;
      visibility: visible !important;
    }

    &.xl\:hidden {
      display: none !important;
    }
  }

  .hero__description {
    // line-height: 1.5em !important;
    p {
      margin: 0;
      padding: 0;
      font: inherit;
      line-height: inherit;
    }
  }
}

;@import "sass-embedded-legacy-load-done:18";