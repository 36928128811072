/*============================================================================*\
  Field select
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin field-select {
  @apply text-black #{!important};
  display: block !important;
  width: 100% !important;
  min-width: 0 !important;
  height: auto !important;
  height: 53px;
  padding: 5px 10px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: initial !important;
  -webkit-appearance: none !important;
  background-color: white !important;
  background-image: url('/themes/bpi/static/svg/field-select-arrow.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center right 6px !important;
  background-clip: initial !important;
  border: 1px solid #{theme('colors.grey.light')} !important;
  border-radius: 10px;
  outline: none !important;
  transition: border-color 0.1s !important;

  &:focus {
    border-color: #{theme('colors.blue.DEFAULT')} !important;
  }

  // Form group status
  .field-group.has-error & {
    @apply text-notice-red #{!important};
    border-color: #{theme('colors.notice-red')} !important;
    outline: none !important;
  }
}

@if $add-class {
  .field-select {
    @include field-select;
  }
}

.product-variants {
  select {
    max-height: 48px;
    padding-right: 16px !important;
    padding-left: 16px !important;
    font-size: 16px;
    font-weight: 600 !important;
    border: 1px solid theme('colors.grey.light') !important;
    border-radius: 100px;
  }
}

;@import "sass-embedded-legacy-load-done:10";