/*============================================================================*\
  Pagination
\*============================================================================*/

/* stylelint-disable */

$add-class: true !default;

/* Basic
\*==========================================================================*/

@if $add-class {
  .pagination {
    .page-list {
      > li {
        > a {
          &.previous,
          &.next {
            @apply text-blue bg-yellow;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border: 0;
            border-radius: 48px !important;

            &:hover,
            &:focus {
              @apply text-white bg-brown-dark;
              border: 0;
            }
          }

          &.previous svg {
            margin-left: -2px;
          }

          &.next svg {
            margin-right: -2px;
          }

          &:not(.previous),
          &:not(.next) {
            border: 2px solid transparent;
            color: #666666;
            display: inline-block;
            font-size: 14px;
            line-height: 36px;
            margin: 0 2px;
            padding: 0;
            width: 40px;
            height: 40px;
          }
        }

        &.current {
          > a {
            &:not(.previous),
            &:not(.next) {
              background-color: transparent;
              border-radius: 0;
              border-color: #f0f0f0;
              color: #666;
            }
          }
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:19";