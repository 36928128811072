/*============================================================================*\
  Modules
\*============================================================================*/

/* stylelint-disable */

[id*="module"].page-customer-account,
#module-jms_wishlist-mywishlist {
  .main-site {
    @apply bg-blue-lightness;
  }

  #wrapper {
    > .container {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
    }
  }

  .page-footer {
    margin-top: 3rem;
  }
}

;@import "sass-embedded-legacy-load-done:26";