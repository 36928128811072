/*============================================================================*\
  Slider
\*============================================================================*/

/* stylelint-disable */

$add-class: true !default;

/* Basic
\*==========================================================================*/

@if $add-class {
  .slider-product-carousel {
    .owl-carousel {
      padding-bottom: 60px;
    }

    .owl-nav {
      display: block;
      top: calc(50% - 60px);
      left: 0;
      right: 0;
      width: 100%;
      height: 0;
      transform: translateY(-50%);

      button.owl-prev,
      button.owl-next {
        @apply text-blue bg-yellow;
        width: 48px;
        height: 48px;
        border-radius: 48px;
        transform: none;
        outline: none;

        span {
          width: 100%;
          height: 100%;
          opacity: 1;
          transition: none;

          &:hover,
          &:focus {
            border-radius: 48px;
          }
        }
      }

      @screen s {
        top: calc(50% - 125px);
      }
    }

    .owl-prev {
      margin-left: 0;
    }

    .owl-next {
      margin-right: 0;
    }

    @screen s {
      .owl-carousel {
        padding-bottom: 125px;
      }
    }

    @screen l {
      overflow: hidden;

      .owl-carousel {
        padding-top: 30px;
        padding-right: 30px;
        padding-left: 30px;
      }

      .owl-stage-outer {
        overflow: visible;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:22";