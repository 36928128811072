/*============================================================================*\
  Link
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin link {
  @apply type-body;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: currentColor;
  text-align: center;
  text-decoration: none;
  user-select: none;
  outline: none;
  transition: color 0.3s;

  * {
    pointer-events: none;
  }

  svg {
    &:first-child:not(:last-child) {
      margin-right: 1em;
    }

    &:last-child:not(:first-child) {
      margin-left: 1em;
    }
  }

  &:active,
  &:hover,
  &:focus {
    outline: none;
  }
}

@if $add-class {
  .link {
    @include link;
  }
}

;@import "sass-embedded-legacy-load-done:7";