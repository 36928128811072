/*==========================================================================*\
  Header
\*==========================================================================*/

/* stylelint-disable */

/* Sticky
\*==========================================================================*/

.header-sticky {
  &.sticky {
    height: auto !important;
    // padding-top: 0.5rem !important;
    // padding-bottom: 0.5rem !important;
  }
}

/* Navigation
\*==========================================================================*/

#mobile-header {
  .logo {
    height: 40px !important;
  }
}

.header-bottom {
  @media (min-width: 993px) {

    .container-fluid {
      padding: 0 !important;
      width: 100%;
      width: calc(100% - #{theme('spacing.mobile-container-margin')});
      max-width: 1216px;
    }

    .navbar-header {
      height: auto;
    }

    .dropdown-menu.shoppingcart-box,
    .dropdown-menu.user-info-content {
      right: 0;
      left: initial;
    }

    .dropdown-menu.user-info-content {
      min-width: 190px;
    }

    .jms-megamenu {
      .nav {
        > li {
          height: auto;
          margin-left: 30px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        li > a {
          @apply type-body font-semibold text-black;
          position: relative;
          padding: 0;

          &:hover,
          &:focus {
            &:before,
            &:after {
              opacity: 1;
            }
          }

          &:before,
          &:after {
            content: '';
            position: absolute;
            bottom: -1em;
            left: 50%;
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            transform-origin: 50% 50%;
            opacity: 0;
            transition: opacity 0.1s;
          }

          &:before {
            @apply bg-yellow;
            transform: translateX(-107%);
          }

          &:after {
            @apply bg-brown-dark;
            transform: translateX(7%);
          }
        }

        li {
          li > a:before,
          li > a:after {
            top: 50%;
            left: initial;
            right: 1rem;
          }

          li > a:before {
            transform: translate(7%, -50%);
          }

          li > a:after {
            transform: translate(-107%, -50%);
          }
        }

        .dropdown-menu {
          border-radius: 10px;
        }
      }
    }
  }

  #cart_block .circle-notify {
    @apply type-small font-regular text-blue bg-yellow;
    width: 18px;
    height: 18px;
    border: 0;
    border-radius: 18px;
    line-height: 18px;
    text-align: center;
  }
}

header {
  transition: transform 0.3s ease-out;
}

.open-menu {
  overflow: hidden;

  header:not(.is-sticky) {
    transform: translateY(-30px);
  }

  .nav-header {
    transform: translateX(0);
  }

  .burger-line--top {
    transform: rotate(45deg) translate(5px, 6px);
  }

  .burger-line--mid {
    opacity: 0;
  }

  .burger-line--bot {
    transform: rotate(-45deg) translate(4px, -5px);
  }
}

.burger-line {
  transition: 0.2s transform ease-out, 0.2s opacity ease-out;
}

.nav-header {
  @media (max-width: 1024px) {
    background: #F2F2F2;
    position: fixed;
    top: 0;
    width: 100%;
    height: 101vh;
    left: 0;
    z-index: 300;
    transition: transform 0.3s ease-out;
    transform: translateX(-100%);
  }
}
;@import "sass-embedded-legacy-load-done:4";