/*============================================================================*\
  Breakpoints
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@if $add-class {
  [data-breakpoint] {
    &::after,
    &::before {
      z-index: -999;
      position: absolute;
      pointer-events: none;
      opacity: 0;
    }

    &::after {
      content: 'xxs,xs,s,m,l,xl,xxl';
    }

    &::before {
      content: 'xxs';
    }

    @screen xs {
      &::before {
        content: 'xs';
      }
    }

    @screen s {
      &::before {
        content: 's';
      }
    }

    @screen m {
      &::before {
        content: 'm';
      }
    }

    @screen l {
      &::before {
        content: 'l';
      }
    }

    @screen xl {
      &::before {
        content: 'xl';
      }
    }

    @screen xxl {
      &::before {
        content: 'xxl';
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:16";