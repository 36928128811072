/*============================================================================*\
  Field text
\*============================================================================*/

.sm-radio {
  position: relative;
  display: inline-block;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
  }

  label {
    padding-top: 0 !important;
    padding-left: 26px !important;
  }

  input:checked {
    + .sm-radio__input + label {
      font-weight: 600;
    }

    + .sm-radio__input {
      &::before {
        opacity: 1;
      }
    }
  }
}

.sm-radio__input {
  position: absolute;
  top: 50%;
  left: 0;
  width: 16px;
  height: 16px;
  pointer-events: none;
  border: 2px solid black;
  border-radius: 50%;
  transform: translateY(-50%);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 8px;
    height: 8px;
    margin-top: -4px;
    margin-left: -4px;
    background: theme('colors.yellow.DEFAULT');
    border-radius: 50%;
    opacity: 0;
    transition: 0.2s opacity;
  }
}

.sm-radio--shipping {
  width: 100%;

  .sm-radio__input {
    top: 5px;
    transform: translateY(0);
  }

  label {
    width: 100%;
  }
}

;@import "sass-embedded-legacy-load-done:12";