/*============================================================================*\
  Button
\*============================================================================*/

/* stylelint-disable */

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin btn {
  @apply font-bold text-black bg-yellow rounded-full;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.65rem 1.75rem;
  max-height: 48px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  user-select: none;
  border-color: #{theme('colors.yellow.DEFAULT')};
  border-style: solid !important;
  border-width: 2px !important;
  outline: none;
  transition: border-color 0.3s, color 0.3s, background-color 0.3s, box-shadow 0.3s;

  * {
    pointer-events: none;
  }

  .buttonText {
    display: inline !important;
    padding: 0 !important;
    font: inherit !important;
    text-transform: initial !important;
    vertical-align: initial !important;
  }

  svg {
    &:first-child:not(:last-child) {
      margin-right: 1em;
    }

    &:last-child:not(:first-child) {
      margin-left: 1em;
    }
  }

  &:active,
  &:hover,
  &:focus {
    @apply text-black bg-yellow-light border-yellow-light;
    outline: none;
  }

  &.btn--disabled,
  &:disabled {
    pointer-events: none;
    box-shadow: none;
    opacity: 0.3;
  }

  @screen s {
    font-size: 16px;
  }
}

@if $add-class {
  .btn {
    @include btn;
  }
}

/* Blue
\*==========================================================================*/

@mixin btn-blue {
  @apply border-blue bg-blue text-white;

  &:active,
  &:hover,
  &:focus {
    @apply text-white bg-brown-dark border-brown-dark;
  }
}

@if $add-class {
  .btn--blue {
    @include btn-blue;
  }
}

/* Stroke
\*==========================================================================*/

@mixin btn-stroke-yellow-to-yellow {
  @apply text-blue border-yellow;
  background-color: transparent;

  &:active,
  &:hover,
  &:focus {
    @apply text-blue border-yellow bg-yellow;
  }
}

@if $add-class {
  .btn--stroke-yellow-to-yellow {
    @include btn-stroke-yellow-to-yellow;
  }
}

/* No appearance
\*==========================================================================*/

@mixin btn-no-appearance {
  margin: 0;
  padding: 0;
  background-color: transparent;
  background-image: none;
  border: 0;
  border-radius: 0;

  &:hover,
  &:focus {
    outline: none;
  }
}

@if $add-class {
  .btn--no-appearance {
    @include btn-no-appearance;
  }
}

.btn--border {
  color: theme('colors.blue.DEFAULT');
  background: none;
  border: 1px solid theme('colors.blue.DEFAULT');

  &:hover {
    background: theme('colors.blue.DEFAULT');
    color: white;
    border: 1px solid theme('colors.blue.DEFAULT');
  }
}

.btn-underline {
  position: relative;
  font-weight: theme('fontWeight.semibold');
  font-size: 16px;

  &::after {
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: currentColor;
    transition: 0.2s transform ease-out;
    transform-origin: left;
  }

  &:hover {
    &::after {
      transform-origin: right;
      transform: scaleX(0);
    }
  }
}

.btn-link {
  text-decoration: none;
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: currentColor;
    transition: 0.2s transform ease-out;
    transform-origin: left;
    transform: scaleX(0);
  }

  &:hover {
    text-decoration: none;
    &::after {
      transform-origin: right;
      transform: scaleX(1);
    }
  }
}
;@import "sass-embedded-legacy-load-done:6";