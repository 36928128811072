/*==========================================================================*\
  Override
\*==========================================================================*/

/* stylelint-disable */

/* Titles
\*==========================================================================*/

body {
  @apply font-body;
}

* {
  &.fa,
  &.far,
  &.fas {
    font-family: 'Font Awesome 5 Free' !important;
  }
}

;@import "sass-embedded-legacy-load-done:3";