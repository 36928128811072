.colissimo-front-widget {
  .modal-dialog .close {
    top: 0;
    margin-top: 0;
  }
}

div#colissimo-container {
  /* stylelint-disable selector-class-pattern */
  .colissimo_widget_bouton-frame {
    @include btn;
    width: auto;
    margin-left: 0;
    padding: 0.5rem 1.783rem !important;
    background-image: none;
  }

  .colissimo_widget_text-lang-active.couleur2 {
    color: theme('colors.yellow.DEFAULT') !important;
  }

  select.colissimo_widget_input-frame {
    @include field-select;
    margin-top: 1.25rem;
  }

  .colissimo_widget_red {
    display: none;
  }

  #colissimo_widget_myUl {
    display: none;
  }
  /* stylelint-enable selector-class-pattern */
}

.colissimo-mobile-valid.js-colissimo-mobile-valid,
.colissimo-mobile-invalid.js-colissimo-mobile-invalid {
  display: inline-block;
}

input[type='tel']#colissimo-pickup-mobile-phone {
  padding-left: 73px !important;
}

;@import "sass-embedded-legacy-load-done:24";